import { getExperienceItems } from 'checkout/selectors/view/experience'
import { magicLoyaltyTransferExperienceId } from 'luxLoyalty/constants/loyaltyBenefitsConstants'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { createSelector } from 'reselect'

const getCanApplyLoyaltyTransferDiscount = createSelector(
  (state: App.State) => state.auth.account.isSpoofed,
  (state: App.State) => state.checkout.cart.items.length,
  (state: App.State) => getExperienceItems(state)[0],
  (state: App.State) => getIsLuxLoyaltyEnabled(state),
  (isSpoofed, itemsCount, experienceItem, isLoyaltyEnabled): boolean => {
    return isLoyaltyEnabled &&
    // only agents can do it
     isSpoofed &&
    // must be the only item in the cart
    itemsCount === 1 &&
    experienceItem &&
    // must be the experience id we're expecting
    experienceItem.experienceId === magicLoyaltyTransferExperienceId
  },
)

export default getCanApplyLoyaltyTransferDiscount
