import React from 'react'
import CruisesSearchPage from '../SearchPage/CruiseSearchPage'
import { RouteComponentProps } from 'react-router'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeroImage from 'content/components/HeroImage'
import LayoutContainer from 'components/Common/LayoutContainer'
import styled from 'styled-components'
import { rem } from 'polished'

const StyledHeroImage = styled(HeroImage)`
  min-height: ${rem(180)};
`

function CruiseShipsLandingPage(props: RouteComponentProps<{ shipNames: string }>) {
  const { shipNames } = props.match.params
  const readableShipNames = shipNames.replace(/\+/g, ' ')

  // You can set a default ship image or fetch it based on the ship name
  const imageId = 'cx0zqdkcwd7ok8d10ag'
  const title = `Cruises on ${readableShipNames}`
  const subtitle = `Explore luxury cruise deals aboard ${readableShipNames}`

  return (
    <>
      <StyledHeroImage
        id={imageId}
        brightness={-25}
        fit="center"
        gravity="center"
      >
        <LayoutContainer relative>
          <Heading variant="heading2" align="center" as="h1" colour="neutral-eight">{title}</Heading>
          <BodyText variant="large" align="center" as="h2" colour="neutral-eight">{subtitle}</BodyText>
        </LayoutContainer>
      </StyledHeroImage>
      <CruisesSearchPage initFilter={{ cruiseShips: [readableShipNames] }} shouldHideCruiseShipsFilters />
    </>
  )
}

export default CruiseShipsLandingPage
