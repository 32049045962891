/**
 * This experience id matches the current ultra lux airport transfer
 * This is the transfer agents will purchase for users when they redeem
 * a airport transfer loyalty benefit
 *
 * Note: this is the prod ID, there is no equiv in test
 */
export const magicLoyaltyTransferExperienceId = 'led:006Ii000002ItIXIA0'
/**
 * This is the amount the experience above costs before any additional
 * coverage. The additional coverage will be charged as normal by
 * the agent.
 */
export const magicLoyaltyTransferExperienceDiscount = 110
