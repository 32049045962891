import { excludeNullOrUndefined } from 'checkout/utils'
import config from 'constants/config'
import { sum } from 'lib/array/arrayUtils'
import { exceedsTicketAvailability } from 'lib/checkout/experiences/cart'
import { getExperienceTimesKey, getTotalSeats } from 'lib/experiences/experienceUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { magicLoyaltyTransferExperienceDiscount } from 'luxLoyalty/constants/loyaltyBenefitsConstants'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'

function calculateValue(price: number, discountPercentage: number) {
  const priceFraction = 1 - (discountPercentage / 100)
  if (priceFraction === 0) { return 0 }
  return price / priceFraction
}

export function mapExperienceToView(
  exp: App.ExperienceOffer,
  cartItem: App.Checkout.ExperienceItem,
  timeSlots: Array<App.ExperienceAvailabilityTimeSlot>,
): App.Checkout.ExperienceItemView {
  const timeSlotTickets = timeSlots.flatMap(slot => slot.tickets.map(ticket => ({ ...ticket, time: slot.time })))
  const requiresTime = exp.bookingType === 'CALENDAR-TIMESLOTS' && !cartItem.isBuyNowBookLater && !cartItem.isGift
  const ticketViews = cartItem.tickets.map(ticketItem => {
    const ticket = timeSlotTickets.find(t => {
      return (
        t.id === ticketItem.ticketId &&
        t.date === ticketItem.date &&
        (!requiresTime || t.time == cartItem.time)
      )
    })
    return mapExperienceTicketToView(ticketItem, ticket, { isBookDates: cartItem.isBookingBNBL })
  }).filter(excludeNullOrUndefined)

  const price = sum(ticketViews, tv => tv.price) - (cartItem.loyaltyTransferDiscount ? magicLoyaltyTransferExperienceDiscount : 0)
  const value = sum(ticketViews, tv => tv.value)
  const taxesAndFees = sum(ticketViews, tv => tv.taxesAndFees)

  const appPriceTickets = ticketViews.filter(tv => tv.discounts.app.amount > 0)
  const appDiscountAmount = sum(appPriceTickets, tv => tv.discounts.app.amount)
  const appDiscountPercentage = appDiscountAmount > 0 ? sum(appPriceTickets, tv => tv.discounts.app.percentage) / appPriceTickets.length : 0

  // The code below assumes that all the selected tickets will always be from the same slot
  const ticketsKeys = new Set(ticketViews.map(ticketView => `${ticketView.id}-${ticketView.date}`))
  const currentSession = timeSlots.find(slot => slot.tickets.some(ticket => ticketsKeys.has(`${ticket.id}-${ticket.date}`)))
  let isSessionPurchaseLimitReached = false

  if (currentSession) {
    const ticketsCounts = ticketViews.reduce<Record<string, number>>((acc, ticket) => {
      acc[ticket.id] = (acc[ticket.id] ?? 0) + ticket.count
      return acc
    }, {})
    isSessionPurchaseLimitReached = !getTotalSeats(currentSession, ticketsCounts).isWithinSessionLimit
  }

  const itemView: App.Checkout.ExperienceItemView = {
    item: cartItem,
    luxLoyaltyProductType: getLuxLoyaltyProductType(exp),
    id: exp.id,
    type: exp.productType,
    experienceId: exp.id,
    primaryCategory: (exp.categories.find(cat => cat.level === 'parent') ?? exp.categories[0])?.name,
    categories: exp.categories,
    location: exp.location.name,
    latitude: exp.location.latitude,
    longitude: exp.location.longitude,
    timezone: exp.location.timezone,
    externalImage: exp.images[0],
    totals: {
      price,
      memberPrice: 0,
      value,
      mobileAppDiscount: {
        amount: appDiscountAmount,
        percentage: appDiscountPercentage,
      },
      taxesAndFees,
      surcharge: 0,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
    discount: calculateDiscount(price, value),
    propertyFees: 0,
    title: exp.name,
    ticketViews,
    leExclusive: exp.leExclusive && config.BRAND === 'luxuryescapes',
    freeCancellation: exp.cancellationPolicies.length > 0,
    pickupPoint: cartItem.pickupPointId ? exp.pickupPoints.find(pp => pp.id === cartItem.pickupPointId) : undefined,
    language: cartItem.languageId ? exp.languages.find(l => l.id === cartItem.languageId) : undefined,
    bookingDate: exp.hasCalendar ? cartItem.date : undefined,
    bookingTime: cartItem.time,
    cancellationPolicies: exp.cancellationPolicies,
    cancellationInfo: exp.copy.cancellationInfo,
    hasCalendar: exp.hasCalendar,
    hasTimeslots: exp.hasTimeslots,
    hideTimeSlots: exp.hideTimeSlots,
    designation: 'Experiences',
    redemptionLocation: cartItem.redemptionLocationId ?
      exp.redemptionLocations.find(rl => rl.id === cartItem.redemptionLocationId) : undefined,
    isBuyNowBookLater: cartItem.isBuyNowBookLater,
    isGift: cartItem.isGift,
    isBookDates: cartItem.isBookingBNBL,
    expirationDate: exp.expirationDate,
    bookByDate: exp.bookByDate,
    isSessionPurchaseLimitReached,
  }

  return itemView
}

interface TicketViewOptions {
  isBookDates?: boolean;
}

export function mapExperienceTicketToView(
  cartTicketItem: App.Checkout.ExperienceItemTicket,
  ticket: App.ExperienceItemTicket | undefined,
  options: TicketViewOptions = {},
): App.Checkout.ExperienceItemView['ticketViews'][number] | null {
  const { ticketId, count } = cartTicketItem

  // If we're just booking dates, then it's already paid for and price is 0
  const price = options.isBookDates ? 0 : (ticket?.price ?? 0) * cartTicketItem.count

  if (cartTicketItem.count === 0) { return null }

  const exceededLimit = ticket ? (exceedsTicketAvailability(count, ticket.purchaseLimit.max)) : false

  return {
    id: ticketId,
    name: ticket?.name ?? cartTicketItem.name,
    fareType: ticket?.fareType ?? '',
    price,
    value: calculateValue(price, ticket?.discounts.base.amount ?? 0),
    discount: ticket?.discount ?? 0,
    discounts: {
      base: {
        amount: ticket?.discounts.base.amount ?? 0,
        percentage: ticket?.discounts.base.percentage ?? 0,
      },
      app: {
        amount: ticket?.discounts.app.amount ?? 0,
        percentage: ticket?.discounts.app.percentage ?? 0,
      },
    },
    count,
    /*
      TODO in future: Give some more information about why the ticket is unavailable. e.g. is it:
      - Actually sold out
      - Not sold out but insufficient availability for the quantity selected
      - Ticket not found
    */
    unavailable: !ticket || exceededLimit,
    purchaseLimit: ticket?.purchaseLimit ?? { min: 1, max: 9999, maxReason: 'AVAILABILITY' },
    taxesAndFees: ticket?.taxesAndFees ?? 0,
    participantsPerUnit: ticket?.participantsPerUnit ?? 1,
    date: ticket?.date ?? '',
  }
}

export function getExperienceItemView(
  item: App.Checkout.ExperienceItem,
  experiences: Record<string, App.ExperienceOffer>,
  experienceTimes: {[id: string]: App.ExperienceTimeSlotsState},
  currencyCode: string,
): App.Checkout.ExperienceItemView | undefined {
  const experience = experiences[item.experienceId]

  const key = getExperienceTimesKey(item.experienceId, item.date, {
    currency: currencyCode,
    pickupPointId: item.pickupPointId,
    redemptionLocationId: item.redemptionLocationId,
    isBuyNowBookLater: item.isBuyNowBookLater,
    ticketMode: item.ticketModeKey,
    isGift: item.isGift,
  })

  const timeSlots = experienceTimes[item.experienceId]?.[key]?.slots
  const hasTickets = timeSlots?.some(slot => slot.tickets.length)

  if (experience && hasTickets) {
    return mapExperienceToView(experience, item, timeSlots!)
  }
}

export function getTransferItemView(
  item: App.Checkout.TransferItem,
  offer: App.ExperienceOffer,
): App.Checkout.TransferItemView {
  return {
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
    item,
    totals: {
      mobileAppDiscount: {
        amount: item.transfer.option?.discounts?.app.amount ?? 0,
        percentage: item.transfer.option?.discounts?.app.percentage ?? 0,
      },
      price: 0,
      memberPrice: 0,
      taxesAndFees: 0,
      value: 0,
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
    },
  }
}
